import React, { useContext } from "react"
import { Formik, Form } from "formik"

import Section from "elements/Section"
import FormCheckbox from "elements/Form/FormCheckbox"

import {
  consentAndAuthInitialValues,
  consentAndAuthValidationSchema,
} from "../../../Request/Summary/utils/formData"
import { RefillsContext } from "../../RefillsContext/RefillsContext"

const termsAndConditionBlurb = `<span className="has-text-weight-normal">I agree to the <a href="/terms-and-conditions" target="_blank">terms and conditions.</a></span>`

const RefillsConsentAndAuthorization = ({ children, handleOnSubmit, flow }) => {
  const { refillsDispatch } = useContext(RefillsContext)

  return (
    <Section title="Consent and Authorization">
      <div className="mx-2 mx-0-mobile mx-0-tablet">
        <Formik
          initialValues={consentAndAuthInitialValues}
          validationSchema={consentAndAuthValidationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <FormCheckbox
                name="consentAndAuthorization"
                values={values.consentAndAuthorization}
                options={[termsAndConditionBlurb]}
                onChange={(event, newValue) =>
                  refillsDispatch({
                    type: `SAVE_AGREE_CONSENT_MEDENSURE`,
                    payload: newValue,
                  })
                }
                isRequired
              />
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default RefillsConsentAndAuthorization
